/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-keep-your-hearing-aids-in-tip-top-shape-for-years-to-come",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-keep-your-hearing-aids-in-tip-top-shape-for-years-to-come",
    "aria-label": "how to keep your hearing aids in tip top shape for years to come permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Keep Your Hearing Aids in Tip-Top Shape for Years to Come"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/tip-top-shape-hearing-aids.png",
    alt: "tip top shape hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ve made an awesome investment in your quality of life. You have more energy, feel less stressed, and your conversations are easier. So, of course, you want your hearing aids to maintain top performance. The good news is, they will with the proper care. It’s important to follow these maintenance suggestions to keep your hearing aids operating like new."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "keep-them-safe-and-store-them-properly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#keep-them-safe-and-store-them-properly",
    "aria-label": "keep them safe and store them properly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Keep them safe and store them properly"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are extremely durable, but can still be damaged if you don’t handle them with care."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Store your hearing aids in a safe place when you take them off, such as their case or charger. You can even get a dehumidifying box to protect against moisture."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Avoid extreme temperatures, don’t use a hairdryer or sit them in the sun."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure children and pets can’t get to them."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turn your hearing aids off when you’re done wearing them to save the battery or charge."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Be careful putting them on and taking them off. Set them down gently, especially on hard surfaces. For extra care, insert them while you’re sitting on your bed or couch in case you drop them."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "keep-them-dry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#keep-them-dry",
    "aria-label": "keep them dry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Keep them dry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s very important to keep your hearing aids away from all liquids and moisture to avoid damage."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Don’t wear your hearing aids in the shower, sauna, when swimming, or during a sweaty workout."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Avoid using hairspray, shaving creams, and other similar products while you’re wearing them, too."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "keep-them-clean",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#keep-them-clean",
    "aria-label": "keep them clean permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Keep them clean"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Establishing a regular cleaning routine at home, and periodically with your hearing professional, is essential to hearing aid performance."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A light cleaning is recommended about once a week.", "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Remove ear wax and other dirt with the cleaning tools that came with your devices as wax buildup can affect performance."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean the outside of your hearing aids with a clean, soft, and dry cloth. Don’t use water or other cleaning products."), "\n"), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Filters or wax guards should be replaced monthly, as wax buildup can distort sounds and damage the hearing aid receiver."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "It’s also important to schedule regular cleanings at least once or twice a year with your hearing care professional."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Following these tips will help you keep your hearing aids operating like new. As a reminder, don’t try to repair your hearing aids if something isn’t working right. Repairs should always be handled by your hearing care professional."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you ever have questions, we’re always here to help! Just give us a call at 888-780-3450."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Assistance from an Expert",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
